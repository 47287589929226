.App {
  text-align: center;
  background-color: rgb(253, 242, 229);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.tab {
  text-indent: 30px;
  text-align: left;
}

.icon {
  justify-content: center;
   vertical-align: middle;
   max-width: calc(30px + 5vmin);
   height:auto;
}

.float {
  font-size: calc(20px + 2vmin);
  float: center;
  margin: 5px;
  color: #0B0B45;
  font-family: "Times New Roman", Times, serif;
}

.sourcelink {
  font-size: calc(5px + .6vmin);
  margin-right: 5px;
  margin-left: 5px;
}

li {
  margin-bottom: 15px;
}

.btn {
  background-color: rgb(43, 184, 232);
  color: white;
  padding: 14px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 12px;
}

.btn:hover, .btn:active {
  background-color: rgb(10, 144, 189);
}
